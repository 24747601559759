import React from "react";
import $ from "jquery";
import {Helpers} from "./config/Helpers";
import {ReactSession} from "react-client-session";

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            urlActive: window.location.pathname,
            endpoint: 'sysprivilege',
            dataSource: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        Helpers.getData(this.state.accessToken,this.state.endpoint,'','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));
            }else{
                const sortMenu = res.sort((a,b) => a.sort - b.sort);
                const bindMenu = sortMenu.filter(parent => parent.level.toString() === '1').map(objParent => {
                    return <ul className="menu-list">
                        <li className="divider py-2 lh-sm">
                            <span className="small">{objParent.name}</span>
                        </li>
                        {
                            sortMenu.filter(child => child.parent_id.toString() === objParent.s_menu_id.toString() && child.level.toString() === '2' && child.path !== '/transaction/analysis' && child.path !== '/transaction/news').map(objChild => {
                                return <li>
                                    <a className={'m-link '+((this.state.urlActive === objChild.path) ? 'active' : '')} href={objChild.path}>
                                        <i className={'fa '+objChild.icon+' m-0'}></i>
                                        <span className="ms-2">{objChild.name}</span>
                                    </a>
                                </li>;
                            })
                        }
                    </ul>;
                });

                this.setState({
                    bindMenu
                });
            }
        });
    };

    render() {
        return (
            <div className="sidebar p-2 py-md-3">
                <div className="container-fluid">
                    <div className="title-text d-flex align-items-center mb-3 mt-1">
                        <h4 className="sidebar-title mb-0 flex-grow-1"><span className="sm-txt">P</span><span>aralyst</span>
                        </h4>
                    </div>
                    <div className="main-menu flex-grow-1 sidebar-padding">
                        {this.state.bindMenu}
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;