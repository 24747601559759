import React from "react";

import App from "../../App";
import {Helpers} from '../../config/Helpers';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

class Company extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            userPrivilege: '',
            breadcrumbs: window.location.pathname.slice(1).split("/"),
            dataSource: [],
            showModal: false,
            endpoint: 'company',
            columns: [
                'm_company_id',
                'name'
            ]
        }
    }

    componentDidMount() {
        this.getPrivilege();
    }

    getPrivilege = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'sysprivilege','','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    userPrivilege: res.filter(x => x.path === window.location.pathname).map(x => {
                        return x;
                    })[0]
                }, () => {
                    this.getData();
                });
            }
        });
    };

    getData = (id) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,this.state.endpoint,id,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataSource: res
                }, () => {
                    $("i").removeClass('fas');
                    $("i").addClass('fa');
                });
            }

            $(".loader-wrapper").css("display", "none");
        });
    };

    selectData = (row) => {
        this.handleModal();

        this.state.columns.forEach(element => {
            this.setState({ [element]: row[element] });
        });
    };

    deleteData = (row) => {
        if (window.confirm("Are you sure want to delete "+row.name+"?") == true) {
            $(".loader-wrapper").css("display", "");
            Helpers.deleteData(this.state.accessToken,this.state.endpoint,row.m_company_id).then(res => {
                if(res.status){
                    toast.success(res.message);

                    this.getData();
                }else{
                    alert(Helpers.customError(res.message));

                    $(".loader-wrapper").css("display", "none");
                }
            });
        }
    };

    addData = () => {
        this.handleModal();

        this.state.columns.forEach(element => {
            this.setState({ [element]: '' });
        });
    };

    handleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value
        }, () => {

        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const formdata = new FormData();
        this.state.columns.forEach(element => {
            formdata.append(element, this.state[element]);
        });

        $(".loader-wrapper").css("display", "");
        Helpers.submitData(this.state.accessToken,this.state.endpoint,this.state.m_company_id,formdata).then(res => {
            if(res.status){
                this.handleModal();

                toast.success(res.message);

                this.getData();
            }else{
                alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }
        });
    };

    render() {
        return (
            <App>
                <div class="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col">
                                <ol class="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a className="text-secondary" href="/">Dashboard</a></li>
                                    {
                                        this.state.breadcrumbs.map((v,i) => {
                                            return (
                                                this.state.breadcrumbs.length === i+1
                                            ) ?
                                                <li className="breadcrumb-item active" aria-current="page">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</li>
                                                :
                                                <li className="breadcrumb-item"><a className="text-secondary" href="#">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</a></li>;
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body border-bottom">
                                        {
                                            (this.state.userPrivilege.is_create > 0) && (
                                                <button className="btn btn-primary mb-3" type="button" onClick={() => {this.addData()}}>Add Data</button>
                                            )
                                        }

                                        {
                                            (this.state.userPrivilege.is_read > 0) && (
                                                <MDBDataTableV5
                                                    hover
                                                    striped
                                                    fullPagination
                                                    entriesOptions={[10, 25, 50, 100]}
                                                    entries={10}
                                                    sortable={false}
                                                    responsive={true}
                                                    searching={(window.innerWidth <= 768) ? false : true}
                                                    data={
                                                        {
                                                            columns: [
                                                                {
                                                                    label: 'No.',
                                                                    field: 'index',
                                                                },
                                                                {
                                                                    label: 'Name',
                                                                    field: 'name',
                                                                },
                                                                {
                                                                    label: 'Action',
                                                                    field: 'action',
                                                                }
                                                            ],
                                                            rows: this.state.dataSource.map((row, index) => {
                                                                return {
                                                                    ...row,
                                                                    index: index+1,
                                                                    action: (
                                                                        <>
                                                                            {
                                                                                (this.state.userPrivilege.is_update > 0) && (
                                                                                    <button type="button" className="btn btn-sm btn-warning btn-rounded btn-sm" onClick={() => {this.selectData(row)}}>
                                                                                        <i className="fa fa-pencil m-0"></i>
                                                                                    </button>
                                                                                )
                                                                            }
                                                                            &nbsp;
                                                                            {
                                                                                (this.state.userPrivilege.is_delete > 0) && (
                                                                                    <button type="button" className="btn btn-sm btn-danger btn-rounded btn-sm" onClick={() => {this.deleteData(row)}}>
                                                                                        <i className="fa fa-trash m-0"></i>
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ),
                                                                };
                                                            }),
                                                        }
                                                    } />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    size={'md'}
                    isOpen={this.state.showModal}>
                    <ModalHeader toggle={() => {this.handleModal()}}>Form Data</ModalHeader>
                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                        <ModalBody>
                            <div className="row g-3">
                                <div className="col-lg-12 col-md-12">
                                    <label class="form-label">Enter Name</label>
                                    <input type="text" className="form-control" placeholder="Name" required
                                           value={this.state.name}
                                           onChange={e => this.setState({name: e.target.value})} />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => {this.handleModal()}}>Close</Button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </App>
        );
    }
}

export default Company;