import React from "react";

import App from "../App";
import {Helpers} from '../config/Helpers';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import queryString from "query-string";

class News extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            urlParam: queryString.parse(window.location.search),
            userPrivilege: '',
            breadcrumbs: window.location.pathname.slice(1).split("/"),
            dataSource: [],
            endpoint: 'news'
        }
    }

    componentDidMount() {
        this.getPrivilege();
    }

    getPrivilege = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'sysprivilege','','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    userPrivilege: res.filter(x => x.path === window.location.pathname).map(x => {
                        return x;
                    })[0]
                }, () => {
                    this.getData();
                });
            }
        });
    };

    getData = (id) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,this.state.endpoint,id,'view='+encodeURIComponent(this.state.urlParam.view)).then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataSource: res.filter(x => x.title).map((obj, i) => {
                        return <a className="card overflow-visible mb-1" href={obj.link} target={'_blank'}>
                            <div className="card-body">
                                <div className="d-flex align-items-center flex-column flex-md-row">
                                    <img className="w120 rounded" src={obj.image}
                                         alt="" />
                                    <div className="media-body ms-md-4 m-0 mt-4 mt-md-0 text-md-start text-center w-100">
                                        <div className="mb-1">
                                            <span style={{'margin-right': '15px'}}><a href="javascript:void(0);">{obj.time.split(' ')[0]}</a></span>
                                            <span className="text-muted">{obj.time.replaceAll(obj.time.split(' ')[0],'')}</span>
                                        </div>
                                        <h5 className="fw-light mb-1">{obj.title}</h5>
                                        <p className="text-muted mb-0" align="justify">{obj.description}</p>
                                    </div>
                                </div>
                            </div>
                        </a>;
                    })
                });
            }

            $(".loader-wrapper").css("display", "none");
        });
    };

    selectData = (row) => {
        const formdata = new FormData();
        formdata.append('based', row.based);
        formdata.append('keywords', row.keywords);
        formdata.append('total_post', row.total_post);
        formdata.append('link', row.link);

        $(".loader-wrapper").css("display", "");
        Helpers.submitData(this.state.accessToken,'search','',formdata).then(res => {
            if(res.status){
                toast.success(res.message);

                setTimeout(function() {
                    window.location.href = '/transaction/analysis?search='+row.keywords;
                }, 1000);
            }else{
                alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }
        });
    };

    render() {
        return (
            <App>
                <div class="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col">
                                <ol class="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a className="text-secondary" href="/">Dashboard</a></li>
                                    {
                                        this.state.breadcrumbs.map((v,i) => {
                                            return (
                                                this.state.breadcrumbs.length === i+1
                                            ) ?
                                                <li className="breadcrumb-item active" aria-current="page">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</li>
                                                :
                                                <li className="breadcrumb-item"><a className="text-secondary" href="#">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</a></li>;
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body border-bottom">
                                        {this.state.dataSource}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default News;