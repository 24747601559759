export class Helpers {
    static BASE_URL = process.env.REACT_APP_BASE_URL;
    static API_URL = process.env.REACT_APP_API_URL;

    static TITLE_SUCCESS = 'Success';
    static TITLE_ERROR = 'Alert';

    static MSG_SUCCESS = 'Success';
    static MSG_ERROR = 'Error, please contact administrator for more information.';

    static MSG_ERROR_FORM = 'Please fill the form';

    static OBJ_STATUS = 'status';
    static OBJ_ERROR = 'errors';

    static optionsDictionaryType = [
        { value: 'positive', label: 'Positive' },
        { value: 'negative', label: 'Negative' }
    ];

    static optionsDictionaryWeight = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' }
    ];

    static login(username,password){
        const formdata = new FormData();
        formdata.append("username", username);
        formdata.append("password", password);

        return fetch(Helpers.API_URL + 'login', {
            method: "POST",
            body: formdata
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getData(authToken, endpoint, id, param){
        let setUrl = Helpers.API_URL + endpoint + ((id) ? '/' + id : '') + ((param) ? '?' + param : '');

        return fetch(setUrl, {
            method: 'GET',
            headers: { Authorization: authToken }
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            return e;
        });
    }

    static submitData(authToken, endpoint, id, data){
        let setUrl = Helpers.API_URL + endpoint + ((id) ? '/' + id : '');

        return fetch(setUrl, {
            method: 'POST',
            headers: { Authorization: authToken },
            body: data
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static deleteData(authToken, endpoint, id){
        let setUrl = Helpers.API_URL + endpoint + '/' + id;

        return fetch(setUrl, {
            method: 'DELETE',
            headers: { Authorization: authToken }
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static customError(errors){
        var msg = '';

        if(typeof errors === "object"){
            Object.values(errors).map((key) => {
                msg = msg + '- ' + key + '\n'
            });
        }else{
            msg = errors;
        }

        return msg;
    }
}