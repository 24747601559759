import React from "react";

import App from "../App";
import {Helpers} from '../config/Helpers';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as d3 from "d3";

class Home extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            userPrivilege: '',
            dataSourceX: [],
            dataSourceDetik: [],
            dataSourceKompas: [],
            dataComponent: [
                {source: 'dataSourceX', title: 'twitter'},
                {source: 'dataSourceDetik', title: 'detik'},
                {source: 'dataSourceKompas', title: 'kompas'}
            ]
        }
    }

    componentDidMount() {
        this.setState({
            getChartContentWidth: parseInt(document.getElementById("chart-content").offsetWidth*0.9)
        }, () => {
            this.getPrivilege();
        });
    }

    getPrivilege = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'sysprivilege','','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    userPrivilege: res.filter(x => x.path === (window.location.pathname === '') ? '/dashboard' : window.location.pathname).map(x => {
                        return x;
                    })[0]
                }, () => {
                    this.loadData().then((res) => {
                        $(".loader-wrapper").css("display", "none");
                    }).catch((e) => {
                        $(".loader-wrapper").css("display", "none");
                    });
                });
            }
        });
    };

    loadData = async () => {
        for(let i=0; i< this.state.dataComponent.length; i++) {
            const checkData = this.state[this.state.dataComponent[i].source];
            if(checkData.length === 0){
                await this.getData(this.state.dataComponent[i].title,this.state.dataComponent[i].source);
            }
        }
    };

    getData = (type, state) => {
        return Helpers.getData(this.state.accessToken,'dashboard/trends',type,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    [state]: res.map((a) => {
                        return {
                            source: state,
                            label: a.label,
                            value: a.value,
                            total_post: a.total_post,
                            link: a.link,
                        }
                    })
                }, () => {
                    this.generateBubble(state);
                });
            }
        });
    };

    generateBubble = (state) => {
        const mainEl = this;

        const width = this.state.getChartContentWidth;
        const height = (this.state.getChartContentWidth > 900) ? 800 : this.state.getChartContentWidth;
        const padding = 25;

        const colorScale = d3.scaleOrdinal()
            .domain(this.state[state].map(d => d.label))
            .range(d3.schemeCategory10);

        const pack = d3.pack()
            .size([width - padding, height - padding])
            .padding(5);

        const hierarchy = d3.hierarchy({children: this.state[state]})
            .sum(d => d.value);

        const root = pack(hierarchy);

        const svg = d3.select("#"+state)
            .attr("width", width)
            .attr("height", height);

        const bubbles = svg.selectAll(".bubble")
            .data(root.descendants().slice(1))
            .enter()
            .append("g")
            .attr("class", "bubble")
            .attr("transform", d => `translate(${d.x + padding}, ${d.y + padding})`);

        bubbles.on('mouseover', function(d, i) {
            d3.select(this).select("circle")
                .attr("r", d => d.r + 5);

            d3.select(this).select("text")
                .attr("font-weight", "700");
        }).on('mouseout', function(d, i) {
            d3.select(this).select("circle")
                .attr("r", d => d.r);

            d3.select(this).select("text")
                .attr("font-weight", "500");
        }).on("click", function(d){
            const getValue = $(this).find('text').attr('data-label');

            if(state === 'dataSourceX'){
                mainEl.search('trend', getValue);
            }else{
                mainEl.viewNews(state, getValue);
            }
        });

        bubbles.append("circle")
            .attr("r", d => d.r)
            .attr("fill", d => colorScale(d.data.label));

        bubbles.append("text")
            .style("text-anchor", "middle")
            .style("font-size", "11px")
            .attr("data-label", d => d.data.label.replace(/&amp;/, "&"))
            .append("tspan")
            .attr("class", "label")
            .text(d => d.data.label.replace(/&amp;/, "&"))
            .each(this.wrap);
    };

    wrap(d) {
        var text = d3.select(this);

        $('#'+d.data.source).children('g').each(function () {
            if($(this).children('text').children('tspan').text() === text.text()){
                if(parseInt($(this)[0].getBBox().width) > parseInt(($(this).children('circle').attr("r")*2))){
                    let str = $(this).children('text').children('tspan').text();
                    let spn = $('<span style="display: none;"></span>').text(str).appendTo('body');
                    let txt = str;
                    while (spn.width() > $(this)[0].getBBox().width) {
                        txt = txt.slice(0, -1);
                        spn.text(txt);
                    }

                    $(this).children('text').children('tspan').text(txt);

                    if(parseInt($(this)[0].getBBox().width) > parseInt(($(this).children('circle').attr("r")*2))){
                        let words = text.text().split(/\s+/).reverse();
                        let word;
                        let line = [];
                        let tspan = text.text(null).append("tspan").attr("x", 0).attr("y", 0).attr("dy", "0em");
                        let y = -15;
                        let i = 0;
                        let wLength = words.length;

                        while (word = words.pop()) {
                            i++;

                            if(i === wLength){
                                word = word+'...';
                            }

                            line.push(word);
                            tspan.text(line.join(" "));
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", "0em").text(word);

                            y+=15;

                        }
                    }
                }
            }
        });
    }

    viewNews = (state, label) => {
        let link = this.state[state].filter(x => x.label === label).map(x => x.link);

        window.location.href = '/transaction/news?view='+encodeURIComponent(link);
    };

    search = (based, words) => {
        if (window.confirm("Are you sure want to search this words?") == true) {
            let total_post = (based === 'trend') ? this.state.dataSourceX.filter(x => x.label === words).map(x => x.total_post) : '';
            let link = (based === 'trend') ? this.state.dataSourceX.filter(x => x.label === words).map(x => x.link) : '';

            const formdata = new FormData();
            formdata.append('based', based);
            formdata.append('keywords', encodeURIComponent(words));
            formdata.append('total_post', total_post);
            formdata.append('link', link);

            $(".loader-wrapper").css("display", "");
            Helpers.submitData(this.state.accessToken,'search','',formdata).then(res => {
                if(res.status){
                    toast.success(res.message);

                    setTimeout(function() {
                        window.location.href = '/transaction/analysis?search='+encodeURIComponent(words);
                    }, 1000);
                }else{
                    alert(Helpers.customError(res.message));

                    $(".loader-wrapper").css("display", "none");
                }
            });
        }
    };

    render() {
        return (
            <App>
                <div class="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
                    <div class="container-fluid">
                        <div class="row g-3 mb-3 align-items-center">
                            <div class="col">
                                <ol class="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a className="text-secondary" href="/">Dashboard</a></li>
                                </ol>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <h1 className="fs-5 color-900 mt-1 mb-0">Welcome back, {this.state.username}!</h1>
                                <small className="text-muted">Checkout what's trending today.</small>
                            </div>
                            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-12 mt-2 mt-md-0">
                                {
                                    (this.state.userPrivilege.is_create > 0) && (
                                        <></>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12" id="chart-content">
                                {
                                    (this.state.userPrivilege.is_read > 0) && (
                                        <>
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Kompas Trending</h6>
                                                </div>
                                                <div className="card-body border-bottom text-center">
                                                    <svg id="dataSourceKompas"></svg>
                                                </div>
                                            </div>

                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Detik Trending</h6>
                                                </div>
                                                <div className="card-body border-bottom text-center">
                                                    <svg id="dataSourceDetik"></svg>
                                                </div>
                                            </div>

                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">X Trending</h6>
                                                </div>
                                                <div className="card-body border-bottom text-center">
                                                    <svg id="dataSourceX"></svg>
                                                </div>
                                            </div>

                                            {/*<div className="card">*/}
                                                {/*<div className="card-header">*/}
                                                    {/*<h6 className="card-title mb-1">Trending</h6>*/}
                                                    {/*<ul className="nav nav-tabs tab-page-toolbar rounded-pill d-inline-flex"*/}
                                                        {/*role="tablist">*/}
                                                        {/*<li className="nav-item">*/}
                                                            {/*<a className="nav-link rounded-pill border-0 active" data-bs-toggle="tab"*/}
                                                               {/*href="#nav-kompas-icon" role="tab">*/}
                                                                {/*Kompas*/}
                                                            {/*</a>*/}
                                                        {/*</li>*/}
                                                        {/*<li className="nav-item">*/}
                                                            {/*<a className="nav-link rounded-pill border-0" data-bs-toggle="tab"*/}
                                                               {/*href="#nav-detik-icon" role="tab">*/}
                                                                {/*Detik*/}
                                                            {/*</a>*/}
                                                        {/*</li>*/}
                                                        {/*<li className="nav-item">*/}
                                                            {/*<a className="nav-link rounded-pill border-0" data-bs-toggle="tab"*/}
                                                               {/*href="#nav-twitter-icon" role="tab">*/}
                                                                {/*X*/}
                                                            {/*</a>*/}
                                                        {/*</li>*/}
                                                    {/*</ul>*/}
                                                {/*</div>*/}
                                                {/*<div className="card-body">*/}
                                                    {/*<div className="tab-content">*/}
                                                        {/*<div className="tab-pane fade show active" id="nav-kompas-icon" role="tabpanel">*/}
                                                            {/*<svg id="dataSourceKompas"></svg>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="tab-pane fade" id="nav-detik-icon" role="tabpanel">*/}
                                                            {/*<svg id="dataSourceDetik"></svg>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="tab-pane fade" id="nav-twitter-icon" role="tabpanel">*/}
                                                            {/*<svg id="dataSourceX"></svg>*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default Home;