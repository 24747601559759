import React from "react";
import $ from "jquery";
import {Helpers} from '../config/Helpers';
import {ReactSession} from "react-client-session";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import {decode as base64_decode, encode as base64_encode} from "base-64";

class Login extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            urlParam: queryString.parse(window.location.search),
            username: '',
            password: ''
        }
    }

    componentDidMount() {
        if(this.state.accessToken){
            window.location.href = '/';
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        $(".loader-wrapper").css("display", "");
        Helpers.login(this.state.username, this.state.password).then(res => {
            if(res.status){
                ReactSession.set("paralyst_access_token", res.access_token);
                ReactSession.set("paralyst_username", this.state.username);
                ReactSession.set("paralyst_password", this.state.password);

                toast.success("Login success!");

                const parUrl = (this.state.urlParam.redirectUrl) ? base64_decode(this.state.urlParam.redirectUrl) : '/';
                setTimeout(function() {
                    window.location.href = parUrl;
                }, 1000);
            }else{
                alert(Helpers.customError(res.message));
            }

            $(".loader-wrapper").css("display", "none");
        })
    };

    render() {
        return (
            <div className="wrapper">
                <ToastContainer />
                <div className="loader-wrapper" style={{'display': 'none'}}>
                    <div className="loader"></div>
                </div>
                <div className="page-body auth px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center">
                                <div style={{'max-width': '25rem'}}>
                                    <div className="mb-4">
                                        <img src="assets/img/paralyst/paralyst_logo_fit.png" width={400} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center align-items-center">
                                <div className="card shadow-sm w-100 p-4 p-md-5" style={{'max-width': '32rem'}}>
                                    <form className="row g-3" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        <div className="col-12 text-center for-mobile">
                                            <div style={{'max-width': '25rem'}}>
                                                <div className="mb-4">
                                                    <img src="assets/img/paralyst/paralyst_logo_fit.png" width={250} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center mb-5">
                                            <h1>Sign in</h1>
                                            <span className="text-muted">To access your dashboard.</span>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-2">
                                                <label className="form-label">Username</label>
                                                <input type="text" className="form-control form-control-lg"
                                                       placeholder="name@example.com" required
                                                       value={this.state.username}
                                                       onChange={e => this.setState({username: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-2">
                                                <div className="form-label">
                                                    <span className="d-flex justify-content-between align-items-center">
                                                        Password
                                                    </span>
                                                </div>
                                                <input type="password" className="form-control form-control-lg"
                                                       placeholder="***************" required
                                                       value={this.state.password}
                                                       onChange={e => this.setState({password: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center mt-4">
                                            <button className="btn btn-lg btn-block btn-dark lift text-uppercase" type="submit">SIGN IN</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Login;