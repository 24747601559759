import React from "react";

import App from "../App";
import {Helpers} from '../config/Helpers';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

class Transactions extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            userPrivilege: '',
            breadcrumbs: window.location.pathname.slice(1).split("/"),
            dataSource: [],
            endpoint: 'search'
        }
    }

    componentDidMount() {
        this.getPrivilege();
    }

    getPrivilege = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'sysprivilege','','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    userPrivilege: res.filter(x => x.path === window.location.pathname).map(x => {
                        return x;
                    })[0]
                }, () => {
                    this.getData();
                });
            }
        });
    };

    getData = (id) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,this.state.endpoint,id,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataSource: res
                }, () => {
                    $("i").removeClass('fas');
                    $("i").addClass('fa');
                });
            }

            $(".loader-wrapper").css("display", "none");
        });
    };

    selectData = (row) => {
        const formdata = new FormData();
        formdata.append('based', row.based);
        formdata.append('keywords', row.keywords);
        formdata.append('total_post', row.total_post);
        formdata.append('link', row.link);

        $(".loader-wrapper").css("display", "");
        Helpers.submitData(this.state.accessToken,'search','',formdata).then(res => {
            if(res.status){
                toast.success(res.message);

                setTimeout(function() {
                    window.location.href = '/transaction/analysis?search='+row.keywords;
                }, 1000);
            }else{
                alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }
        });
    };

    render() {
        return (
            <App>
                <div class="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col">
                                <ol class="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a className="text-secondary" href="/">Dashboard</a></li>
                                    {
                                        this.state.breadcrumbs.map((v,i) => {
                                            return (
                                                this.state.breadcrumbs.length === i+1
                                            ) ?
                                                <li className="breadcrumb-item active" aria-current="page">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</li>
                                                :
                                                <li className="breadcrumb-item"><a className="text-secondary" href="#">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</a></li>;
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body border-bottom">
                                        {
                                            (this.state.userPrivilege.is_read > 0) && (
                                                <MDBDataTableV5
                                                    hover
                                                    striped
                                                    fullPagination
                                                    entriesOptions={[10, 25, 50, 100]}
                                                    entries={10}
                                                    sortable={false}
                                                    responsive={true}
                                                    searching={(window.innerWidth <= 768) ? false : true}
                                                    data={
                                                        {
                                                            columns: [
                                                                {
                                                                    label: 'No.',
                                                                    field: 'index',
                                                                },
                                                                {
                                                                    label: 'Based',
                                                                    field: 'based',
                                                                },
                                                                {
                                                                    label: 'Keywords',
                                                                    field: 'keywords',
                                                                },
                                                                {
                                                                    label: 'View Details',
                                                                    field: 'action',
                                                                }
                                                            ],
                                                            rows: this.state.dataSource.map((row, index) => {
                                                                return {
                                                                    ...row,
                                                                    index: index+1,
                                                                    action: (
                                                                        <button type="button" className="btn btn-sm btn-primary btn-rounded btn-sm" onClick={() => {this.selectData(row)}}>
                                                                            <i className="fa fa-paper-plane m-0"></i>
                                                                        </button>
                                                                    ),
                                                                };
                                                            }),
                                                        }
                                                    } />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </App>
        );
    }
}

export default Transactions;