import React from "react";

import App from "../../App";
import {Helpers} from '../../config/Helpers';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';

class Privilege extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            userPrivilege: '',
            breadcrumbs: window.location.pathname.slice(1).split("/"),
            dataSource: [],
            dataMenu: [],
            dataRole: [],
            showModal: false,
            endpoint: 'sysprivilege',
            columns: [
                's_privilege_id',
                's_role_id',
                's_menu_id',
                'is_create',
                'is_read',
                'is_update',
                'is_delete'
            ]
        }
    }

    componentDidMount() {
        this.getPrivilege();
    }

    getPrivilege = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'sysprivilege','','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    userPrivilege: res.filter(x => x.path === window.location.pathname).map(x => {
                        return x;
                    })[0]
                }, () => {
                    this.getMenu();
                    this.getRole();
                    this.getData();
                });
            }
        });
    };

    getData = (id) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,this.state.endpoint,id,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataSource: res
                }, () => {
                    $("i").removeClass('fas');
                    $("i").addClass('fa');
                });
            }

            $(".loader-wrapper").css("display", "none");
        });
    };

    getMenu = () => {
        Helpers.getData(this.state.accessToken,'sysmenu','','').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataMenu: res.map(function(item) {
                        return {
                            value: item.s_menu_id,
                            label: ((item.level.toString() === '1') ? '' : res.filter(x => x.s_menu_id === item.parent_id).map(x => x.name) + ' - ') + item.name
                        };
                    })
                });
            }
        });
    };

    getRole = () => {
        Helpers.getData(this.state.accessToken,'sysrole','','').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataRole: res.map(function(item) {
                        return {
                            value: item.s_role_id,
                            label: item.name
                        };
                    })
                });
            }
        });
    };

    selectData = (row) => {
        this.handleModal();

        this.state.columns.forEach(element => {
            this.setState({ [element]: row[element] });
        });
    };

    deleteData = (row) => {
        if (window.confirm("Are you sure want to delete this item?") == true) {
            $(".loader-wrapper").css("display", "");
            Helpers.deleteData(this.state.accessToken,this.state.endpoint,row.s_privilege_id).then(res => {
                if(res.status){
                    toast.success(res.message);

                    this.getData();
                }else{
                    alert(Helpers.customError(res.message));

                    $(".loader-wrapper").css("display", "none");
                }
            });
        }
    };

    addData = () => {
        this.handleModal();

        this.state.columns.forEach(element => {
            this.setState({ [element]: '' });
        });
    };

    handleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    handleChange = (event,state) => {
        this.setState({
            [state]: event.value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const formdata = new FormData();
        this.state.columns.forEach(element => {
            formdata.append(element, this.state[element]);
        });

        $(".loader-wrapper").css("display", "");
        Helpers.submitData(this.state.accessToken,this.state.endpoint,this.state.s_privilege_id,formdata).then(res => {
            if(res.status){
                this.handleModal();

                toast.success(res.message);

                this.getData();
            }else{
                alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }
        });
    };

    render() {
        return (
            <App>
                <div class="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col">
                                <ol class="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a className="text-secondary" href="/">Dashboard</a></li>
                                    {
                                        this.state.breadcrumbs.map((v,i) => {
                                            return (
                                                this.state.breadcrumbs.length === i+1
                                            ) ?
                                                <li className="breadcrumb-item active" aria-current="page">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</li>
                                                :
                                                <li className="breadcrumb-item"><a className="text-secondary" href="#">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</a></li>;
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body border-bottom">
                                        {
                                            (this.state.userPrivilege.is_create > 0) && (
                                                <button className="btn btn-primary mb-3" type="button" onClick={() => {this.addData()}}>Add Data</button>
                                            )
                                        }

                                        {
                                            (this.state.userPrivilege.is_read > 0) && (
                                                <MDBDataTableV5
                                                    hover
                                                    striped
                                                    fullPagination
                                                    entriesOptions={[10, 25, 50, 100]}
                                                    entries={10}
                                                    sortable={false}
                                                    responsive={true}
                                                    searching={(window.innerWidth <= 768) ? false : true}
                                                    data={
                                                        {
                                                            columns: [
                                                                {
                                                                    label: 'No.',
                                                                    field: 'index',
                                                                },
                                                                {
                                                                    label: 'Role',
                                                                    field: 'role',
                                                                },
                                                                {
                                                                    label: 'Menu',
                                                                    field: 'menu',
                                                                },
                                                                {
                                                                    label: 'Create',
                                                                    field: 'create',
                                                                },
                                                                {
                                                                    label: 'Read',
                                                                    field: 'read',
                                                                },
                                                                {
                                                                    label: 'Update',
                                                                    field: 'update'
                                                                },
                                                                {
                                                                    label: 'Delete',
                                                                    field: 'delete'
                                                                },
                                                                {
                                                                    label: 'Action',
                                                                    field: 'action',
                                                                }
                                                            ],
                                                            rows: this.state.dataSource.map((row, index) => {
                                                                return {
                                                                    ...row,
                                                                    index: index+1,
                                                                    role: this.state.dataRole.filter(x => x.value === row.s_role_id).map(x => x.label),
                                                                    menu: this.state.dataMenu.filter(x => x.value === row.s_menu_id).map(x => x.label),
                                                                    create: (row.is_create > 0) ? <span className="badge bg-secondary">Yes</span> : <span className="badge bg-warning">No</span>,
                                                                    read: (row.is_read > 0) ? <span className="badge bg-secondary">Yes</span> : <span className="badge bg-warning">No</span>,
                                                                    update: (row.is_update > 0) ? <span className="badge bg-secondary">Yes</span> : <span className="badge bg-warning">No</span>,
                                                                    delete: (row.is_delete > 0) ? <span className="badge bg-secondary">Yes</span> : <span className="badge bg-warning">No</span>,
                                                                    action: (
                                                                        <>
                                                                            {
                                                                                (this.state.userPrivilege.is_update > 0) && (
                                                                                    <button type="button" className="btn btn-sm btn-warning btn-rounded btn-sm" onClick={() => {this.selectData(row)}}>
                                                                                        <i className="fa fa-pencil m-0"></i>
                                                                                    </button>
                                                                                )
                                                                            }
                                                                            &nbsp;
                                                                            {
                                                                                (this.state.userPrivilege.is_delete > 0) && (
                                                                                    <button type="button" className="btn btn-sm btn-danger btn-rounded btn-sm" onClick={() => {this.deleteData(row)}}>
                                                                                        <i className="fa fa-trash m-0"></i>
                                                                                    </button>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ),
                                                                };
                                                            }),
                                                        }
                                                    } />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    size={'lg'}
                    isOpen={this.state.showModal}>
                    <ModalHeader toggle={() => {this.handleModal()}}>Form Data</ModalHeader>
                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                        <ModalBody>
                            <div className="row g-3">
                                <div className="col-lg-6 col-md-12">
                                    <label className="form-label">Role</label>
                                    <Select
                                        options={this.state.dataRole}
                                        value={this.state.dataRole.filter((item) => item.value === this.state.s_role_id)}
                                        onChange={event => this.handleChange((event) ? event : '','s_role_id')}
                                        isClearable={true}/>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label class="form-label">Menu</label>
                                    <Select
                                        options={this.state.dataMenu}
                                        value={this.state.dataMenu.filter((item) => item.value === this.state.s_menu_id)}
                                        onChange={event => this.handleChange((event) ? event : '','s_menu_id')}
                                        isClearable={true}/>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label className="form-label">Allow Create</label><br/>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">Yes</label>
                                        <input className="form-check-input" type="radio" id="create1" name="create" value={1} required
                                               checked={(this.state.is_create > 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_create')}/>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">No</label>
                                        <input className="form-check-input" type="radio" id="create2" name="create" value={0}
                                               checked={(this.state.is_create <= 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_create')} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label className="form-label">Allow Read</label><br/>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">Yes</label>
                                        <input className="form-check-input" type="radio" id="read1" name="read" value={1} required
                                               checked={(this.state.is_read > 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_read')}/>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">No</label>
                                        <input className="form-check-input" type="radio" id="read2" name="read" value={0}
                                               checked={(this.state.is_read <= 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_read')} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label className="form-label">Allow Update</label><br/>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">Yes</label>
                                        <input className="form-check-input" type="radio" id="update1" name="update" value={1} required
                                               checked={(this.state.is_update > 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_update')}/>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">No</label>
                                        <input className="form-check-input" type="radio" id="update2" name="update" value={0}
                                               checked={(this.state.is_update <= 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_update')} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <label className="form-label">Allow Delete</label><br/>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">Yes</label>
                                        <input className="form-check-input" type="radio" id="delete1" name="delete" value={1} required
                                               checked={(this.state.is_delete > 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_delete')}/>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">No</label>
                                        <input className="form-check-input" type="radio" id="delete2" name="delete" value={0}
                                               checked={(this.state.is_delete <= 0) ? true : false}
                                               onChange={event => this.handleChange(event.target,'is_delete')} />
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => {this.handleModal()}}>Close</Button>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </App>
        );
    }
}

export default Privilege;