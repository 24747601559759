import React from "react";
import {ReactSession} from "react-client-session";
import $ from "jquery";
import {Helpers} from "./config/Helpers";
import {toast} from "react-toastify";

class Header extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            dataSource: [],
            searchWords: ''
        }
    }

    componentDidMount() {
        this.getData();
        this.getHistory();
    }

    getData = (id) => {
        Helpers.getData(this.state.accessToken,'dashboard/trends','twitter','').then(res => {
            if (res.hasOwnProperty("status")) {
                if(Helpers.customError(res.message) === 'Token Time Expire.'){
                    if (window.confirm('Token Time Expire. Please Relogin!')) {
                        this.execLogout();
                    }
                }
            }else{
                this.setState({
                    dataSource: res
                });

                const bindTrending = res.map((obj, i) => {
                    return (i < 5) ? <a className="list-group-item list-group-item-action text-truncate"
                                        href="javascript:void(0);" onClick={(event) => {
                        this.search('trend', obj.label)
                    }}>
                        <div className="fw-bold">{obj.label}</div>
                        <small className="text-muted">Click to find out about this trending</small>
                    </a> : '';
                });

                this.setState({
                    bindTrending
                });
            }
        });
    };

    getHistory = () => {
        Helpers.getData(this.state.accessToken,'search','','').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));
            }else{
                const bindHistory = res.map((obj, i) => {
                    return (i < 5) ? <a className="small rounded py-1 px-2 m-1 fw-normal bg-primary text-white"
                                        href="javascript:void(0);" onClick={(event) => {
                        this.search('search', decodeURIComponent(obj.keywords))
                    }}>{decodeURIComponent(obj.keywords)}</a> : '';
                });

                this.setState({
                    bindHistory
                });
            }
        });
    };

    handleLogout = () => {
        if (window.confirm('Are you sure you want to exit the app?')) {
            this.execLogout();
        }
    };

    execLogout = () => {
        ReactSession.set("paralyst_access_token", "");
        ReactSession.set("paralyst_username", "");
        ReactSession.set("paralyst_password", "");

        window.location.href = '/login';
    };

    submitSearch = () => {
        if(this.state.searchWords){
            this.search('search', this.state.searchWords)
        }else{
            toast.error('Please type at least on word');
        }
    };

    search = (based, words) => {
        if (window.confirm("Are you sure want to search this words?") == true) {
            let total_post = (based === 'trend') ? this.state.dataSource.filter(x => x.label === words).map(x => x.total_post) : '';
            let link = (based === 'trend') ? this.state.dataSource.filter(x => x.label === words).map(x => x.link) : '';

            const formdata = new FormData();
            formdata.append('based', based);
            formdata.append('keywords', encodeURIComponent(words));
            formdata.append('total_post', total_post);
            formdata.append('link', link);

            $(".loader-wrapper").css("display", "");
            Helpers.submitData(this.state.accessToken,'search','',formdata).then(res => {
                if(res.status){
                    toast.success(res.message);

                    setTimeout(function() {
                        window.location.href = '/transaction/analysis?search='+encodeURIComponent(words);
                    }, 1000);
                }else{
                    alert(Helpers.customError(res.message));

                    $(".loader-wrapper").css("display", "none");
                }
            });
        }
    };

    render() {
        return (
            <header className="page-header sticky-top px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                <div className="container-fluid">

                    <nav className="navbar">
                        <div className="d-flex">
                            <button type="button"
                                    className="btn btn-link d-none d-xl-block sidebar-mini-btn p-0 text-primary">
                            <span className="hamburger-icon">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </span>
                            </button>
                            <button type="button"
                                    className="btn btn-link d-block d-xl-none menu-toggle p-0 text-primary">
                            <span className="hamburger-icon">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </span>
                            </button>
                            <a href="/"
                               className="brand-icon d-flex align-items-center mx-2 mx-sm-3 text-primary">
                                <img src="/assets/img/paralyst/paralyst_logo_fit.png" width={100} />
                            </a>
                        </div>
                        <div className="header-left flex-grow-1 d-none d-md-block">
                            <div className="main-search px-3 flex-fill">
                                <input className="form-control" type="text" placeholder="Enter your search keyword"
                                       value={this.state.searchWords}
                                       onChange={e => this.setState({searchWords: e.target.value})}
                                       onKeyDown={e => e.key === 'Enter' ? this.submitSearch() : ''}/>
                                    <div className="card shadow rounded-4 search-result slidedown">
                                        <div className="card-body">
                                            <small className="text-uppercase text-muted">Recent searches</small>
                                            <div className="d-flex flex-wrap align-items-start mt-2 mb-4">
                                                {this.state.bindHistory}
                                            </div>
                                            <small className="text-uppercase text-muted">Suggestions based on today's trending</small>
                                            <div className="card list-group list-group-flush list-group-custom mt-2">
                                                {this.state.bindTrending}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <ul className="header-right justify-content-end d-flex align-items-center mb-0">
                            <li>
                                <div className="dropdown morphing scale-left user-profile mx-lg-3 mx-2">
                                    <a className="nav-link dropdown-toggle rounded-circle after-none p-0" href="#"
                                       role="button" data-bs-toggle="dropdown">
                                        <img className="avatar img-thumbnail rounded-circle shadow"
                                             src="/assets/img/profile_av.png" alt="" />
                                    </a>
                                    <div className="dropdown-menu border-0 rounded-4 shadow p-0">
                                        <div className="card border-0 w240">
                                            <div className="card-body border-bottom d-flex">
                                                <img className="avatar rounded-circle"
                                                     src="/assets/img/profile_av.png" alt="" />
                                                    <div className="flex-fill ms-3">
                                                        <h6 className="card-title mb-0">Hello</h6>
                                                        <span className="text-muted">{this.state.username}</span>
                                                    </div>
                                            </div>
                                            <a onClick={() => {this.handleLogout()}}
                                               className="btn bg-secondary text-light text-uppercase rounded-0">Sign
                                                out</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <div className="main-search flex-fill for-mobile">
                        <input className="form-control" type="text" placeholder="Enter your search keyword"
                               value={this.state.searchWords}
                               onChange={e => this.setState({searchWords: e.target.value})}
                               onKeyDown={e => e.key === 'Enter' ? this.submitSearch() : ''}/>
                        <div className="card shadow rounded-4 search-result slidedown search-result-mobile">
                            <div className="card-body">
                                <small className="text-uppercase text-muted">Recent searches</small>
                                <div className="d-flex flex-wrap align-items-start mt-2 mb-4">
                                    {this.state.bindHistory}
                                </div>
                                <small className="text-uppercase text-muted">Suggestions based on today's trending</small>
                                <div className="card list-group list-group-flush list-group-custom mt-2">
                                    {this.state.bindTrending}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </header>
        );
    }
}

export default Header;