import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import Page404 from "./pages/404";
import Login from "./pages/Login";
import Home from "./pages/Home";

import Transactions from "./pages/Transactions";
import Analysis from "./pages/transaction/Analysis";
import News from "./pages/News";

import MasterCompany from "./pages/master/Company";
import MasterUsers from "./pages/master/Users";
import MasterDictionary from "./pages/master/Dictionary";

import SystemMenu from "./pages/system/Menu";
import SystemPrivilege from "./pages/system/Privilege";
import SystemRole from "./pages/system/Role";
import SystemUsers from "./pages/system/Users";

class Path extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="dashboard" element={<Home />} />
                    <Route path="*" element={<Page404 />} />

                    <Route path="transaction">
                        <Route path="history" element={<Transactions />} />
                        <Route path="analysis" element={<Analysis />} />
                        <Route path="news" element={<News />} />
                    </Route>

                    <Route path="master">
                        <Route path="company" element={<MasterCompany />} />
                        <Route path="users" element={<MasterUsers />} />
                        <Route path="dictionary" element={<MasterDictionary />} />
                    </Route>

                    <Route path="system">
                        <Route path="menu" element={<SystemMenu />} />
                        <Route path="privilege" element={<SystemPrivilege />} />
                        <Route path="role" element={<SystemRole />} />
                        <Route path="users" element={<SystemUsers />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default Path;