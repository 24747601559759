import React from "react";

import App from "../../App";
import {Helpers} from '../../config/Helpers';
import {ReactSession} from "react-client-session";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import queryString from "query-string";

class Analysis extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            urlParam: queryString.parse(window.location.search),
            userPrivilege: '',
            breadcrumbs: window.location.pathname.slice(1).split("/"),
            dataTweet: [],
            dataGoogle: [],
            dataSummary: [],
            dataAnalysis: [],
            showModalTwitter: false,
            showModalGoogle: false,
            showModalAnalysis: false,
            tweetDetails: '',
            tweetComponents: '',
            tweerAuthor: '',
            googleDetails: '',
            googleComponents: '',
            noImage: 'https://www.legrand.co.id/modules/custom/legrand_ecat/assets/img/no-image.png'
        }
    }

    componentDidMount() {
        this.getPrivilege();
    }

    getPrivilege = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'sysprivilege','','auth=true').then(res => {
            if (res.hasOwnProperty("status")) {
                //alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    userPrivilege: res.filter(x => x.path === window.location.pathname).map(x => {
                        return x;
                    })[0]
                }, () => {
                    this.getTweet();
                });
            }
        });
    };

    getTweet = (id) => {
        let search = 'search=' + encodeURIComponent(this.state.urlParam.search);

        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'tweets','',search).then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    dataTweet: res
                }, () => {
                    $("i").removeClass('fas');
                    $("i").addClass('fa');

                    this.getGoogle();
                });
            }
        }).catch((e) => {
            alert(Helpers.customError(e));

            $(".loader-wrapper").css("display", "none");
        });
    };

    getGoogle = (id) => {
        let search = 'search=' + encodeURIComponent(this.state.urlParam.search);

        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'google','',search).then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));

                $(".loader-wrapper").css("display", "none");
            }else{
                this.setState({
                    dataGoogle: res
                }, () => {
                    $("i").removeClass('fas');
                    $("i").addClass('fa');

                    this.getSummary(res[0].t_search_id);
                });
            }
        }).catch((e) => {
            alert(Helpers.customError(e));

            $(".loader-wrapper").css("display", "none");
        });
    };

    getSummary = (id) => {
        Helpers.getData(this.state.accessToken,'summary',id,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.setState({
                    dataSummary: res
                });
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            alert(Helpers.customError(e));

            $(".loader-wrapper").css("display", "none");
        });
    };

    handleModal = (state) => {
        this.setState({ [state]: !this.state[state] });
    };

    selectDataTwitter = (row) => {
        let tweetComponents = JSON.parse(decodeURIComponent(row.tweet_components));

        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'tweets/user',tweetComponents.author_id,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.handleModal('showModalTwitter');

                this.setState({
                    tweetDetails: row,
                    tweetComponents: tweetComponents,
                    tweerAuthor: res
                }, () => {
                    console.log(this.state.tweerAuthor);
                });
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            alert(Helpers.customError(e));

            $(".loader-wrapper").css("display", "none");
        });
    };

    selectDataGoogle = (row) => {
        let googleComponents = JSON.parse(decodeURIComponent(row.google_components));

        this.handleModal('showModalGoogle');

        this.setState({
            googleDetails: row,
            googleComponents: googleComponents
        }, () => {
            console.log(this.state.tweerAuthor);
        });
    };

    selectDataAnalysis = (value) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getData(this.state.accessToken,'analytics/search',this.state.dataGoogle[0].t_search_id,'').then(res => {
            if (res.hasOwnProperty("status")) {
                alert(Helpers.customError(res.message));
            }else{
                this.handleModal('showModalAnalysis');

                this.setState({
                    dataAnalysis: res.filter(x => x.type.toString() === value).map(x => {
                        return x;
                    })
                });
            }

            $(".loader-wrapper").css("display", "none");
        }).catch((e) => {
            alert(Helpers.customError(e));

            $(".loader-wrapper").css("display", "none");
        });
    };

    render() {
        return (
            <App>
                <div class="page-toolbar px-xl-4 px-sm-2 px-0 py-3">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col">
                                <ol class="breadcrumb bg-transparent mb-0">
                                    <li className="breadcrumb-item"><a className="text-secondary" href="/">Dashboard</a></li>
                                    {
                                        this.state.breadcrumbs.map((v,i) => {
                                            return (
                                                this.state.breadcrumbs.length === i+1
                                            ) ?
                                                <li className="breadcrumb-item active" aria-current="page">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</li>
                                                :
                                                <li className="breadcrumb-item"><a className="text-secondary" href="#">{v.replace(/(^\w|\s\w)/g, m => m.toUpperCase())}</a></li>;
                                        })
                                    }
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">
                        {
                            (this.state.userPrivilege.is_read > 0) && (
                                <>
                                    <div className="row g-3 mb-3 row-deck">
                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <svg className="position-absolute top-0 end-0 mt-4 me-3"
                                                         xmlns="http://www.w3.org/2000/svg" width="26"
                                                         fill="currentColor" viewBox="0 0 16 16">
                                                        <path
                                                            d="M15.985 8.50001H8.20698L2.70698 14C3.82973 14.9905 5.20793 15.6461 6.68482 15.8922C8.1617 16.1383 9.678 15.9649 11.0613 15.3919C12.4445 14.8189 13.6392 13.8692 14.5095 12.6508C15.3797 11.4325 15.8916 9.99434 15.985 8.50001ZM1.99998 13.292C1.00944 12.1693 0.353862 10.7911 0.107801 9.31418C-0.138261 7.83729 0.0350724 6.32099 0.608086 4.93774C1.1811 3.55448 2.1308 2.35979 3.34916 1.48954C4.56752 0.619291 6.00565 0.108414 7.49998 0.0150146V7.79301L1.99998 13.293V13.292Z"
                                                            fill="black"/>
                                                        <path className="fill-secondary"
                                                              d="M8.5 7.50001V0.0150146C10.4452 0.136897 12.279 0.964622 13.6572 2.34279C15.0354 3.72096 15.8631 5.5548 15.985 7.50001H8.5Z"
                                                              fill="black"/>
                                                    </svg>
                                                    <div className="mb-2 text-uppercase">BAD WORDS</div>
                                                    <div><span className="h4">{this.state.dataSummary.bad_words}</span> <span
                                                        className="small text-muted">/ {this.state.dataSummary.total_words}</span>
                                                    </div>
                                                    <small className="text-muted">Percentage gain from total words <strong>{this.state.dataSummary.bad_words_percentage+'%'}</strong></small>
                                                    <br/><br/>
                                                    <a href="javascript:void(0);" onClick={(event) => {
                                                        this.selectDataAnalysis('negative')
                                                    }}>
                                                        <small className="text-muted">
                                                            <strong className="text-align-right">View details <i className="fa fa-external-link"></i>
                                                            </strong>
                                                        </small>
                                                    </a>
                                                </div>
                                                <div className="progress" style={{'height': '4px'}}>
                                                    <div className="progress-bar bg-secondary" role="progressbar"
                                                         style={{'width': this.state.dataSummary.bad_words_percentage+'%'}} aria-valuenow={this.state.dataSummary.bad_words_percentage} aria-valuemin="0"
                                                         aria-valuemax="100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <svg className="position-absolute top-0 end-0 mt-4 me-3"
                                                         xmlns="http://www.w3.org/2000/svg" width="26"
                                                         fill="currentColor" viewBox="0 0 16 16">
                                                        <path
                                                            d="M15.985 8.50001H8.20698L2.70698 14C3.82973 14.9905 5.20793 15.6461 6.68482 15.8922C8.1617 16.1383 9.678 15.9649 11.0613 15.3919C12.4445 14.8189 13.6392 13.8692 14.5095 12.6508C15.3797 11.4325 15.8916 9.99434 15.985 8.50001ZM1.99998 13.292C1.00944 12.1693 0.353862 10.7911 0.107801 9.31418C-0.138261 7.83729 0.0350724 6.32099 0.608086 4.93774C1.1811 3.55448 2.1308 2.35979 3.34916 1.48954C4.56752 0.619291 6.00565 0.108414 7.49998 0.0150146V7.79301L1.99998 13.293V13.292Z"
                                                            fill="black"/>
                                                        <path className="fill-secondary"
                                                              d="M8.5 7.50001V0.0150146C10.4452 0.136897 12.279 0.964622 13.6572 2.34279C15.0354 3.72096 15.8631 5.5548 15.985 7.50001H8.5Z"
                                                              fill="black"/>
                                                    </svg>
                                                    <div className="mb-2 text-uppercase">GOOD WORDS</div>
                                                    <div><span className="h4">{this.state.dataSummary.good_words}</span> <span
                                                        className="small text-muted">/ {this.state.dataSummary.total_words}</span>
                                                    </div>
                                                    <small className="text-muted">Percentage gain from total words <strong>{this.state.dataSummary.good_words_percentage+'%'}</strong></small>
                                                    <br/><br/>
                                                    <a href="javascript:void(0);" onClick={(event) => {
                                                        this.selectDataAnalysis('positive')
                                                    }}>
                                                        <small className="text-muted">
                                                            <strong className="text-align-right">View details <i className="fa fa-external-link"></i>
                                                            </strong>
                                                        </small>
                                                    </a>
                                                </div>
                                                <div className="progress" style={{'height': '4px'}}>
                                                    <div className="progress-bar bg-secondary" role="progressbar"
                                                         style={{'width': '55%'}} aria-valuenow="78" aria-valuemin="0"
                                                         aria-valuemax="100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <svg className="position-absolute top-0 end-0 mt-4 me-3"
                                                         xmlns="http://www.w3.org/2000/svg" width="26"
                                                         fill="currentColor" viewBox="0 0 16 16">
                                                        <path
                                                            d="M15.985 8.50001H8.20698L2.70698 14C3.82973 14.9905 5.20793 15.6461 6.68482 15.8922C8.1617 16.1383 9.678 15.9649 11.0613 15.3919C12.4445 14.8189 13.6392 13.8692 14.5095 12.6508C15.3797 11.4325 15.8916 9.99434 15.985 8.50001ZM1.99998 13.292C1.00944 12.1693 0.353862 10.7911 0.107801 9.31418C-0.138261 7.83729 0.0350724 6.32099 0.608086 4.93774C1.1811 3.55448 2.1308 2.35979 3.34916 1.48954C4.56752 0.619291 6.00565 0.108414 7.49998 0.0150146V7.79301L1.99998 13.293V13.292Z"
                                                            fill="black"/>
                                                        <path className="fill-secondary"
                                                              d="M8.5 7.50001V0.0150146C10.4452 0.136897 12.279 0.964622 13.6572 2.34279C15.0354 3.72096 15.8631 5.5548 15.985 7.50001H8.5Z"
                                                              fill="black"/>
                                                    </svg>
                                                    <div className="mb-2 text-uppercase">BAD WORDS SCORE</div>
                                                    <div><span className="h4">{this.state.dataSummary.bad_words_score}</span> <span
                                                        className="small text-muted">/ {this.state.dataSummary.bad_words_max_score}</span>
                                                    </div>
                                                    <small className="text-muted">Percentage gain from max bad score <strong>{this.state.dataSummary.bad_words_score_percentage+'%'}</strong></small>
                                                    <br/><br/>
                                                    <a href="javascript:void(0);" onClick={(event) => {
                                                        this.selectDataAnalysis('negative')
                                                    }}>
                                                        <small className="text-muted">
                                                            <strong className="text-align-right">View details <i className="fa fa-external-link"></i>
                                                            </strong>
                                                        </small>
                                                    </a>
                                                </div>
                                                <div className="progress" style={{'height': '4px'}}>
                                                    <div className="progress-bar bg-secondary" role="progressbar"
                                                         style={{'width': '55%'}} aria-valuenow="78" aria-valuemin="0"
                                                         aria-valuemax="100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-sm-6">
                                            <div className="card overflow-hidden">
                                                <div className="card-body">
                                                    <svg className="position-absolute top-0 end-0 mt-4 me-3"
                                                         xmlns="http://www.w3.org/2000/svg" width="26"
                                                         fill="currentColor" viewBox="0 0 16 16">
                                                        <path
                                                            d="M15.985 8.50001H8.20698L2.70698 14C3.82973 14.9905 5.20793 15.6461 6.68482 15.8922C8.1617 16.1383 9.678 15.9649 11.0613 15.3919C12.4445 14.8189 13.6392 13.8692 14.5095 12.6508C15.3797 11.4325 15.8916 9.99434 15.985 8.50001ZM1.99998 13.292C1.00944 12.1693 0.353862 10.7911 0.107801 9.31418C-0.138261 7.83729 0.0350724 6.32099 0.608086 4.93774C1.1811 3.55448 2.1308 2.35979 3.34916 1.48954C4.56752 0.619291 6.00565 0.108414 7.49998 0.0150146V7.79301L1.99998 13.293V13.292Z"
                                                            fill="black"/>
                                                        <path className="fill-secondary"
                                                              d="M8.5 7.50001V0.0150146C10.4452 0.136897 12.279 0.964622 13.6572 2.34279C15.0354 3.72096 15.8631 5.5548 15.985 7.50001H8.5Z"
                                                              fill="black"/>
                                                    </svg>
                                                    <div className="mb-2 text-uppercase">GOOD WORDS SCORE</div>
                                                    <div><span className="h4">{this.state.dataSummary.good_words_score}</span> <span
                                                        className="small text-muted">/ {this.state.dataSummary.good_words_max_score}</span>
                                                    </div>
                                                    <small className="text-muted">Percentage gain from max good score <strong>{this.state.dataSummary.good_words_score_percentage+'%'}</strong></small>
                                                    <br/><br/>
                                                    <a href="javascript:void(0);" onClick={(event) => {
                                                        this.selectDataAnalysis('positive')
                                                    }}>
                                                        <small className="text-muted">
                                                            <strong className="text-align-right">View details <i className="fa fa-external-link"></i>
                                                            </strong>
                                                        </small>
                                                    </a>
                                                </div>
                                                <div className="progress" style={{'height': '4px'}}>
                                                    <div className="progress-bar bg-secondary" role="progressbar"
                                                         style={{'width': '55%'}} aria-valuenow="78" aria-valuemin="0"
                                                         aria-valuemax="100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <div className="card text-center bg-primary-gradient">
                                                <div
                                                    className="card-body d-flex align-items-center justify-content-center">
                                                    <div>
                                                        <h4>Conclusion</h4>
                                                        <p className="card-text fs-6">
                                                            {this.state.dataSummary.conclusion}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h6 className="card-title mb-0">Table Result</h6>
                                                    <ul className="nav nav-tabs tab-page-toolbar rounded-pill d-inline-flex"
                                                        role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link rounded-pill border-0 active" data-bs-toggle="tab"
                                                               href="#nav-google-icon" role="tab">
                                                                <i className="fa fa-google me-2"></i>Google
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link rounded-pill border-0" data-bs-toggle="tab"
                                                               href="#nav-twitter-icon" role="tab">
                                                                <i className="fa fa-twitter me-2"></i>X
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <div className="tab-content">
                                                        <div className="tab-pane fade" id="nav-twitter-icon" role="tabpanel">
                                                            <MDBDataTableV5
                                                                hover
                                                                striped
                                                                fullPagination
                                                                entriesOptions={[10, 25, 50, 100]}
                                                                entries={10}
                                                                sortable={false}
                                                                responsive={true}
                                                                searching={(window.innerWidth <= 768) ? false : true}
                                                                data={
                                                                    {
                                                                        columns: [
                                                                            {
                                                                                label: 'No.',
                                                                                field: 'index',
                                                                            },
                                                                            {
                                                                                label: 'Username',
                                                                                field: 'username',
                                                                            },
                                                                            {
                                                                                label: 'Tweet',
                                                                                field: 'tweet_text_format',
                                                                            },
                                                                            {
                                                                                label: 'Action',
                                                                                field: 'action',
                                                                            }
                                                                        ],
                                                                        rows: this.state.dataTweet.map((row, index) => {
                                                                            return {
                                                                                ...row,
                                                                                index: index+1,
                                                                                username: (row.tweet_components) ? JSON.parse(row.tweet_components).entities.urls.filter(x => x.expanded_url.includes('twitter.com')).map(x => {
                                                                                    return '@'+x.expanded_url.split('/')[3];
                                                                                })[0] : '',
                                                                                tweet_text_format: (
                                                                                    <div dangerouslySetInnerHTML={{ __html: row.tweet_text }} />
                                                                                ),
                                                                                action: (
                                                                                    <>
                                                                                        <button type="button" className="btn btn-sm btn-primary btn-rounded btn-sm" onClick={() => {this.selectDataTwitter(row)}}>
                                                                                            <i className="fa fa-eye m-0"></i>
                                                                                        </button>
                                                                                    </>
                                                                                ),
                                                                            };
                                                                        }),
                                                                    }
                                                                } />
                                                        </div>
                                                        <div className="tab-pane fade show active" id="nav-google-icon" role="tabpanel">
                                                            <MDBDataTableV5
                                                                hover
                                                                striped
                                                                fullPagination
                                                                entriesOptions={[10, 25, 50, 100]}
                                                                entries={10}
                                                                sortable={false}
                                                                responsive={true}
                                                                searching={(window.innerWidth <= 768) ? false : true}
                                                                data={
                                                                    {
                                                                        columns: [
                                                                            {
                                                                                label: 'No.',
                                                                                field: 'index',
                                                                            },
                                                                            {
                                                                                label: 'Headline',
                                                                                field: 'google_text_format',
                                                                            },
                                                                            {
                                                                                label: 'Action',
                                                                                field: 'action',
                                                                            }
                                                                        ],
                                                                        rows: this.state.dataGoogle.map((row, index) => {
                                                                            return {
                                                                                ...row,
                                                                                index: index+1,
                                                                                google_text_format: (
                                                                                    <div dangerouslySetInnerHTML={{ __html: row.google_text }} />
                                                                                ),
                                                                                action: (
                                                                                    <>
                                                                                        <button type="button" className="btn btn-sm btn-primary btn-rounded btn-sm" onClick={() => {this.selectDataGoogle(row)}}>
                                                                                            <i className="fa fa-eye m-0"></i>
                                                                                        </button>
                                                                                    </>
                                                                                ),
                                                                            };
                                                                        }),
                                                                    }
                                                                } />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Modal
                                            size={'xl'}
                                            isOpen={this.state.showModalTwitter}>
                                            <ModalHeader toggle={() => {this.handleModal('showModalTwitter')}}>Tweet Details</ModalHeader>
                                            <ModalBody>
                                                <div className="card mb-3">
                                                    <div className="card-body border-bottom">
                                                        <div className="row">
                                                            <div className="col-lg-1 for-desktop">
                                                                <img className="avatar-custom img-thumbnail rounded-circle shadow"
                                                                     src={(this.state.tweerAuthor) ? this.state.tweerAuthor.profile_image_url : this.state.noImage} alt="" />
                                                            </div>
                                                            <div className="col-lg-11">
                                                                <div className="row">
                                                                    <div className="col-lg-12" style={{'display': 'flex'}}>
                                                                        <h6 className="card-title m-0 mr-5">{(this.state.tweerAuthor) ? this.state.tweerAuthor.name : ''}</h6>
                                                                        <h6 className="m-0">@{(this.state.tweerAuthor) ? this.state.tweerAuthor.username : ''}</h6>
                                                                    </div>
                                                                </div>
                                                                <label className="mb-2">
                                                                    {(this.state.tweetDetails) ? this.state.tweetDetails.tweet_text : ''}
                                                                </label>
                                                                <div className="row mb-2">
                                                                    <div className="col-lg-2 col-3">
                                                                        <i className={'fa fa-comment-o m-0'}></i>
                                                                        <span className="ms-2">{(this.state.tweetComponents) ? this.state.tweetComponents.public_metrics.reply_count : '0'}</span>
                                                                    </div>
                                                                    <div className="col-lg-2 col-3">
                                                                        <i className={'fa fa-retweet m-0'}></i>
                                                                        <span className="ms-2">{(this.state.tweetComponents) ? this.state.tweetComponents.public_metrics.retweet_count : '0'}</span>
                                                                    </div>
                                                                    <div className="col-lg-2 col-3">
                                                                        <i className={'fa fa-heart-o m-0'}></i>
                                                                        <span className="ms-2">{(this.state.tweetComponents) ? this.state.tweetComponents.public_metrics.like_count : '0'}</span>
                                                                    </div>
                                                                </div>
                                                                <a href={(this.state.tweetComponents) ? this.state.tweetComponents.entities.urls[0].expanded_url : '#'} target={'_blank'}>
                                                                    <small className="text-muted">
                                                                        <strong className="text-align-right">Visit original tweet <i className="fa fa-external-link"></i>
                                                                        </strong>
                                                                    </small>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    ((this.state.tweetComponents) && (this.state.tweetComponents.hasOwnProperty("context_annotations"))) && (
                                                        <div className="card">
                                                            <div className="card-header pb-0">
                                                                <h6 className="card-title m-0">Context Annotations</h6>
                                                            </div>
                                                            <div className="card-body border-bottom">
                                                                <MDBDataTableV5
                                                                    hover
                                                                    striped
                                                                    fullPagination
                                                                    entriesOptions={[10, 25, 50, 100]}
                                                                    entries={10}
                                                                    sortable={false}
                                                                    responsive={true}
                                                                    searching={(window.innerWidth <= 768) ? false : true}
                                                                    data={
                                                                        {
                                                                            columns: [
                                                                                {
                                                                                    label: 'No.',
                                                                                    field: 'index',
                                                                                },
                                                                                {
                                                                                    label: 'Domain',
                                                                                    field: 'domain',
                                                                                },
                                                                                {
                                                                                    label: 'Description',
                                                                                    field: 'description_domain',
                                                                                },
                                                                                {
                                                                                    label: 'Entity',
                                                                                    field: 'entity',
                                                                                },
                                                                                {
                                                                                    label: 'Description',
                                                                                    field: 'description_entity',
                                                                                }
                                                                            ],
                                                                            rows: this.state.tweetComponents.context_annotations.map((row, index) => {
                                                                                setTimeout(function(){
                                                                                    $("i").removeClass('fas');
                                                                                    $("i").addClass('fa');
                                                                                }, 1000);

                                                                                return {
                                                                                    ...row,
                                                                                    index: index+1,
                                                                                    domain: row.domain.name,
                                                                                    description_domain: row.domain.description,
                                                                                    entity: row.entity.name,
                                                                                    description_entity: row.entity.description
                                                                                };
                                                                            }),
                                                                        }
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button onClick={() => {this.handleModal('showModalTwitter')}}>Close</Button>
                                            </ModalFooter>
                                        </Modal>

                                        <Modal
                                            size={'xl'}
                                            isOpen={this.state.showModalGoogle}>
                                            <ModalHeader toggle={() => {this.handleModal('showModalGoogle')}}>Google Details</ModalHeader>
                                            <ModalBody>
                                                <div className="card mb-3">
                                                    <div className="card-body border-bottom">
                                                        <div className="row">
                                                            <div className="col-lg-1 for-desktop">
                                                                <img className="avatar-custom img-thumbnail rounded-circle shadow"
                                                                     src={(this.state.googleComponents) ? (this.state.googleComponents.pagemap.hasOwnProperty("cse_image")) ? this.state.googleComponents.pagemap.cse_image[0].src : this.state.noImage : this.state.noImage} alt="" />
                                                            </div>
                                                            <div className="col-lg-11">
                                                                <div className="row">
                                                                    <div className="col-lg-12" style={{'display': 'flex'}}>
                                                                        <h6 className="card-title m-0 mr-5">{(this.state.googleComponents) ? this.state.googleComponents.displayLink : ''}</h6>
                                                                    </div>
                                                                </div>
                                                                <label className="mb-2">
                                                                    {(this.state.googleComponents) ? this.state.googleComponents.snippet : ''}
                                                                </label>
                                                                <a href={(this.state.googleComponents) ? this.state.googleComponents.link : '#'} target={'_blank'}>
                                                                    <small className="text-muted">
                                                                        <strong className="text-align-right">Visit original feed <i className="fa fa-external-link"></i>
                                                                        </strong>
                                                                    </small>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    (this.state.googleComponents) && (
                                                        <iframe src={(this.state.googleComponents) ? this.state.googleComponents.link : '#'} width="100%" height="250"></iframe>
                                                    )
                                                }
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button onClick={() => {this.handleModal('showModalGoogle')}}>Close</Button>
                                            </ModalFooter>
                                        </Modal>

                                        <Modal
                                            size={'xl'}
                                            isOpen={this.state.showModalAnalysis}>
                                            <ModalHeader toggle={() => {this.handleModal('showModalAnalysis')}}>Data Details</ModalHeader>
                                            <ModalBody>
                                                <MDBDataTableV5
                                                    hover
                                                    striped
                                                    fullPagination
                                                    entriesOptions={[10, 25, 50, 100]}
                                                    entries={10}
                                                    sortable={false}
                                                    responsive={true}
                                                    searching={(window.innerWidth <= 768) ? false : true}
                                                    data={
                                                        {
                                                            columns: [
                                                                {
                                                                    label: 'No.',
                                                                    field: 'index',
                                                                },
                                                                {
                                                                    label: 'Source',
                                                                    field: 'source',
                                                                },
                                                                {
                                                                    label: 'Sentence',
                                                                    field: 'sentence_format',
                                                                },
                                                                {
                                                                    label: 'Type',
                                                                    field: 'type',
                                                                },
                                                                {
                                                                    label: 'Dictionary',
                                                                    field: 'dictionary',
                                                                },
                                                                {
                                                                    label: 'Weight',
                                                                    field: 'weight',
                                                                }
                                                            ],
                                                            rows: this.state.dataAnalysis.map((row, index) => {
                                                                setTimeout(function(){
                                                                    $("i").removeClass('fas');
                                                                    $("i").addClass('fa');
                                                                }, 1000);

                                                                return {
                                                                    ...row,
                                                                    index: index+1,
                                                                    sentence_format: (
                                                                        <div dangerouslySetInnerHTML={{ __html: row.sentence }} />
                                                                    ),
                                                                };
                                                            }),
                                                        }
                                                    } />
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button onClick={() => {this.handleModal('showModalAnalysis')}}>Close</Button>
                                            </ModalFooter>
                                        </Modal>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </App>
        );
    }
}

export default Analysis;