import React from "react";

import App from "../App";
import {ReactSession} from "react-client-session";

class Page404 extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
            username: (ReactSession.get("paralyst_username")) ? ReactSession.get("paralyst_username") : '',
            userPrivilege: '',
            dataSource: []
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <App>
                <div className="page-body px-xl-4 px-sm-2 px-0 py-lg-2 py-1">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="card shadow-sm w-100 p-4 p-md-5">
                                    <form className="row g-3">
                                        <div className="col-12 text-center mb-4">
                                            <img src="assets/images/auth-404.svg" className="w240 mb-4" alt=""/>
                                            <h1 className="display-1">404</h1>
                                            <h5>OOP! PAGE NOT FOUND</h5>
                                            <span className="text-muted">Sorry, the page you're looking for doesn't exist. if you think something is broken, report a problem.</span>
                                        </div>
                                        <div className="col-12 text-center">
                                            <a href="./" title=""
                                               className="btn btn-lg btn-block btn-dark lift text-uppercase">Back to
                                                Home</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </App>
        );
    }
}

export default Page404;