import React from "react";
import {ReactSession} from "react-client-session";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {encode as base64_encode} from "base-64";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accessToken: (ReactSession.get("paralyst_access_token")) ? ReactSession.get("paralyst_access_token") : '',
        }
    }

    componentDidMount() {
        if(!this.state.accessToken){
            window.location.href = '/login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    render() {
        return (
            <>
                <ToastContainer />

                <div className="loader-wrapper" style={{'display': 'none'}}>
                    <div className="loader"></div>
                </div>

                <Sidebar/>
                <div className="wrapper">
                    <Header/>
                    {this.props.children}
                </div>
            </>
        );
    }
}

export default App;